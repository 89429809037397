<template>
  <div>
    <div class="main-title">音频书列表</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">
        <span class="fl-l" aria-label="分类">
          <el-select v-model="searchData.audio_books_cate_id" clearable placeholder="分类" size="mini">
            <el-option :value="item.id" :label="item.name" v-for="item in cate" :key="item.id"></el-option>
          </el-select>
        </span>
        <span class="fl-l margin-l-sm" aria-label="名称">
          <el-input v-model="searchData.name" clearable placeholder="名称" size="mini"></el-input>
        </span>
        <span class="fl-l margin-l-sm" aria-label="作者">
          <el-input v-model="searchData.author" clearable placeholder="作者" size="mini"></el-input>
        </span>

        <span class="fl-l margin-l-sm" aria-label="是否免费">
          <el-select v-model="searchData.is_free" clearable placeholder="是否免费" size="mini">
            <el-option :value="1" label="是"></el-option>
            <el-option :value="0" label="否"></el-option>
          </el-select>
        </span>

        <span class="fl-l margin-l-sm" aria-label="是否推荐">
          <el-select v-model="searchData.is_recommend" clearable placeholder="是否推荐" size="mini">
            <el-option :value="1" label="是"></el-option>
            <el-option :value="0" label="否"></el-option>
          </el-select>
        </span>

        <span class="fl-l margin-l-sm" aria-label="状态">
          <el-select v-model="searchData.status" clearable placeholder="状态" size="mini">
            <el-option :value="0" label="下架"></el-option>
            <el-option :value="1" label="上架"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
        </span>

        <span class="fl-r">
          <router-link to="/audioBooks/add" class="router-link">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </span>
      </div>

      <div class="table-content margin-t-sm">
        <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" v-loading="loading"
          style="width: 100%;background: #fff;">
          <el-table-column label="ID" property="id" width="80" align="center">
          </el-table-column>

          <el-table-column label="音频书名称" property="name" align="center">
          </el-table-column>

          <el-table-column label="图片" property="pic" align="center">
            <template slot-scope="scope">
              <el-image :src="path + scope.row.cover">
              </el-image>
            </template>
          </el-table-column>




          <el-table-column property="created_at" label="创建时间" align="center">
          </el-table-column>


          <el-table-column property="days" label="有效期" align="center">
          </el-table-column>

          <el-table-column label="状态" property="status" align="center">
            <template slot-scope="scope">
              <div>
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                  @change="updataStatus(scope.row.id, scope.row.status)" active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="是否免费" property="pic" align="center">
            <template slot-scope="scope">
              <el-tag>
                {{scope.row.is_free == 0?'否':'是'}}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column label="VIP是否免费" property="pic" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_free == 1">是</el-tag>
              <el-tag v-else>{{scope.row.vip_free == 0?'否':'是'}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column property="price" label="价格" align="center">
          </el-table-column>
          <el-table-column property="vip_price" label="VIP价格" align="center">
          </el-table-column>



          <el-table-column width="300" label="操作">
            <template slot-scope="scope">
              <span class="margin-r-sm">
                <el-link slot="reference" type="primary" @click="editAimActivity(scope.row.id)">编辑
                </el-link>
              </span>
              <span class="margin-r-sm">
                <el-link slot="reference" type="primary" @click="detailsAimActivity(scope.row.id)">详情
                </el-link>
              </span>
              <span>
                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row.id)">
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch margin-l-sm">
        <span class="fl-l">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
            :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
  name: "aimActivity",
  data() {
    return {
      //图片前缀
      path: config.imageUrl,
      //表单加载动画
      loading: false,
      //表单数据
      tableData: [],
      //搜索的数据
      searchData: {},
      //分类
      cate: [],
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  mounted() {
    this.getCatelist()
    this.getList();
  },
  methods: {
    ...mapActions('hearBook', ['getAllCate', 'getAudioBooksList', 'setAudioBooksDel']),
    ...mapActions('common', ['setStatus']),

    //搜索
    search() {
      this.getList();
    },
    //分页
    setPageSize(pageSize) {
      this.pageSize = pageSize
      this.getList()
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    //状态改变
    async updataStatus(id, status) {
      let from = {
        id,
        "model": "AudioBooks\\BookModel",
        status,
      }
      const { res_info } = await this.setStatus(from)
      if (res_info != 'ok') return
      this.$message.success('修改成功');
      await this.getList()
    },
    //编辑
    editAimActivity(row) {
      this.$router.push('/audioBooks/detail/' + row)
    },
    //详情
    detailsAimActivity(row) {
      this.$router.push('/audioBooks/details/' + row + '/manage')
    },
    //删除
    async deleted(row) {
      const { res_info } = await this.setAudioBooksDel(row)
      if (res_info != 'ok') return
      this.$message.success('删除成功');
      await this.getList()
    },
    async getCatelist() {
      const { res_info, data } = await this.getAllCate()
      if (res_info != 'ok') return
      this.cate = data
    },
    //获取列表数据
    async getList() {
      this.loading = true
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { res_info, data } = await this.getAudioBooksList(from)
      if (res_info != 'ok') return
      this.total = data.total
      this.tableData = data.list
      this.loading = false
    }
  }
}
</script>
